import React, { useState, useRef, useEffect } from "react";
import { CoursesSectionsMap } from "./CoursesPageBuilder.model";
import { useScrollListener } from "@/outscal-commons-frontend/Hooks/useOnScrollListener";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import { useRouter } from "next/router";
import PageBuilderView from "./PageBuilder.view";

const CoursesPageBuilder = ({
  sections,
  rightStickySections,
  fixedSections,
  showFooter = true,
  pageWidth_px,
  pageGap_px,
  mainLayoutWidth,
  rightStickyLayoutWidth,
  spaceBetweenSections_px,
  pageMargin_px = 0,
  mainLayoutPadding_px = 0,
  mainLayoutBorder_px = 0,
  fixedSectionsBottom_px = 48,
  mainLayoutBg = false,
  showAfterSection = "",
}) => {
  const router = useRouter();
  const [isFixedVisible, setIsFixedVisible] = useState(!showAfterSection);
  const fixedSectionRef = useRef(null);
  const [threshold, setThreshold] = useState(0);

  useEffect(() => {
    if (showAfterSection) {
      const targetSection = document.getElementById(showAfterSection);
      if (targetSection) {
        setThreshold(targetSection.offsetHeight);
      }
    }
  }, [showAfterSection]);

  useScrollListener(
    (isVisible) => {
      setIsFixedVisible(isVisible);
    },
    threshold,
    5,
    typeof window !== "undefined" ? window : null
  );

  pageWidth_px = pageWidth_px || 1220;
  pageGap_px = pageGap_px || 20;
  mainLayoutWidth = mainLayoutWidth || 70;
  rightStickyLayoutWidth = rightStickyLayoutWidth || 30;
  spaceBetweenSections_px = spaceBetweenSections_px || 20;
  pageMargin_px = pageMargin_px || 0;
  mainLayoutPadding_px = mainLayoutPadding_px || 0;
  mainLayoutBorder_px = mainLayoutBorder_px || 0;
  fixedSectionsBottom_px =
    fixedSectionsBottom_px !== null ? fixedSectionsBottom_px : 48;

  const isPaidLead = () => {
    return (
      UTMManager?.instance?.sessionUtm?.utm_medium === "paid" &&
      (router.pathname === "/courses" ||
        router.pathname.includes("/learning-paths") ||
        router.asPath.includes("/game-development-courses") ||
        router.pathname === "/course/[courseSlug]")
    );
  };
  return (
    <PageBuilderView
      sections={sections}
      rightStickySections={rightStickySections}
      fixedSections={fixedSections}
      showFooter={showFooter}
      pageWidth_px={pageWidth_px}
      pageGap_px={pageGap_px}
      mainLayoutWidth={mainLayoutWidth}
      rightStickyLayoutWidth={rightStickyLayoutWidth}
      spaceBetweenSections_px={spaceBetweenSections_px}
      pageMargin_px={pageMargin_px}
      mainLayoutPadding_px={mainLayoutPadding_px}
      mainLayoutBorder_px={mainLayoutBorder_px}
      fixedSectionsBottom_px={fixedSectionsBottom_px}
      mainLayoutBg={mainLayoutBg}
      showAfterSection={showAfterSection}
      isPaidLead={isPaidLead}
      isFixedVisible={isFixedVisible}
      fixedSectionRef={fixedSectionRef}
      sectionsMap={CoursesSectionsMap}
    />
  );
};

export const getPageBuilderProps = ({
  sections = [],
  spaceBetweenSections_px,
  rightStickySections = [],
  fixedSections = [],
  pageWidth_px,
  pageGap_px,
  mainLayoutWidth,
  rightStickyLayoutWidth,
  showFooter = true,
  pageMargin_px = 0,
  mainLayoutPadding_px = 0,
  mainLayoutBorder_px = 0,
  fixedSectionsBottom_px = 48,
  mainLayoutBg = false,
  showAfterSection,
} = {}) => {
  return {
    showFooter,
    sections,
    rightStickySections,
    fixedSections,
    spaceBetweenSections_px,
    pageWidth_px,
    pageGap_px,
    mainLayoutWidth,
    rightStickyLayoutWidth,
    pageMargin_px,
    mainLayoutPadding_px,
    mainLayoutBorder_px,
    fixedSectionsBottom_px,
    mainLayoutBg,
    showAfterSection,
  };
};

export default CoursesPageBuilder;
