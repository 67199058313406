import dynamic from "next/dynamic";
const ImageGridB = dynamic(() => import("./ImageGridB/ImageGridB.view"));

const MediaBView = dynamic(() => import("./MediaB/MediaB.view"));
const PricingA = dynamic(() => import("./PricingA/PricingA.controller"));
const CoursesListing = dynamic(() =>
  import("./CoursesListing/CoursesListing.controller")
);
const HaloTxtImgB = dynamic(() =>
  import("./HaloTxtImgB/HaloTxtImgB.controller")
);
const FaqsList = dynamic(() => import("./FaqsList/FaqsList.controller"));
const OnlyMediaController = dynamic(() =>
  import("./OnlyMedia/OnlyMedia.controller")
);
const CMSDynmCrdsView = dynamic(() =>
  import("@/mvcComponents/v2/DynmCrds/DynmCrds.view").then(
    (mod) => mod.CMSDynmCrdsView
  )
);
const CMSHaloSctnView = dynamic(() =>
  import("@/mvcComponents/v2/HaloSctn/HaloSctn.view").then(
    (mod) => mod.CMSHaloSctnView
  )
);

const HTMLEmbedView = dynamic(() =>
  import("@/mvcComponents/v2/HTMLEmbed/HTMLEmbed.view")
);

export const CoursesSectionsMap = {
  "only-media": OnlyMediaController,
  "halo-sctn": CMSHaloSctnView,
  "courses-listing": CoursesListing,
  "halo-txt-img-b": HaloTxtImgB,
  "img-grid-b": ImageGridB,
  "media-b": MediaBView,
  "pricing-a": PricingA,
  html: HTMLEmbedView,
  faq: FaqsList,
  "dynm-crds": CMSDynmCrdsView,
};
